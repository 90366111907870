import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ProvidersContext } from "./contexts";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA9Txu3sfb9A7DHZA3EtdzFFN8bn7P7iKY",
  authDomain: "hassanalrawi-eb46f.firebaseapp.com",
  databaseURL: "https://hassanalrawi-eb46f.firebaseio.com",
  projectId: "hassanalrawi-eb46f",
  storageBucket: "hassanalrawi-eb46f.appspot.com",
  messagingSenderId: "938932894212",
  appId: "1:938932894212:web:e39630fb610d9c4e3c5351"
};

// Initialize Firebase
initializeApp(firebaseConfig);


ReactDOM.render(
  <React.StrictMode>
    <ProvidersContext>
      <App />
    </ProvidersContext>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
